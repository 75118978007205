/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret.position-top {
    border-top-color: white;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
  color: black;
  background-color: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: #282828 !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border: #282828 !important;
  background: #282828 !important;
}
